var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{on:{"click":_vm.save}},[_vm._v(" Salva ")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.hasEmail)?_c('VsInput',{staticClass:"vs-mb-4",attrs:{"label":"Indirizzo email contiene"},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)}},model:{value:(_vm.filters.email),callback:function ($$v) {_vm.$set(_vm.filters, "email", $$v)},expression:"filters.email"}}):_vm._e(),(_vm.hasMobile)?_c('VsInput',{staticClass:"vs-mb-4",attrs:{"label":"Telefono cellulare principale contiene"},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)}},model:{value:(_vm.filters.mobile),callback:function ($$v) {_vm.$set(_vm.filters, "mobile", $$v)},expression:"filters.mobile"}}):_vm._e(),_c('VsSelect',{staticClass:"vs-mb-4",attrs:{"label":"Stato iscrizione è uguale a","variant":"multiSelection","clearable":"","options":_vm.statusOptions},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}}),_c('VsInputSearch',{staticClass:"vs-mb-4",attrs:{"label":"Contiene etichette","variant":"multiSelection","debounce":500,"options":_vm.tagsOptions,"formatBadgeLabel":_vm.formatBadgeLabel},on:{"search":_vm.getTags},model:{value:(_vm.filters.tags),callback:function ($$v) {_vm.$set(_vm.filters, "tags", $$v)},expression:"filters.tags"}}),_c('VsSelect',{attrs:{"label":"Metodo di ricerca","options":[
            {
                label: 'Deve rispettare tutte le regole indicate',
                value: 'and'
            },
            {
                label: 'Deve rispettare almeno una delle regole indicate',
                value: 'or'
            } ]},model:{value:(_vm.filters.searchJoin),callback:function ($$v) {_vm.$set(_vm.filters, "searchJoin", $$v)},expression:"filters.searchJoin"}}),_c('VsNotification',{staticClass:"vs-mt-4",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" Hai bisogno di un filtro avanzato? "),_c('VsButton',{attrs:{"variant":"link","target":"_blank"},on:{"click":_vm.goToFilterLegacy}},[_vm._v("Vai alla vecchia visualizzazione")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }