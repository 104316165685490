





















































import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsManageTableColumnsModal',
})
export default class extends Vue {
    private dialog = false
    private fields: any[] = []
    resolve: any = null
    reject: any = null
    $refs: any

    get valid () {
        return this.fields.find(el => el.visible)
    }

    public open (fields: any[]) {
        this.dialog = true
        this.fields = JSON.parse(JSON.stringify(fields))
        return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    }

    public save () {
        this.resolve(this.fields)
    }

    public cancel (): void {
        this.reject(false)
    }

    public close () {
        this.dialog = false
    }
}
