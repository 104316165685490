























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsButtonActionsContainer from '@/components/VsButtonActionsContainer/Index.vue'
import VsFixedTopActions from '@/components/VsFixedTopActions/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsContactsListTopMultiActions',
    components: {
        VsButtonActionsContainer,
        VsFixedTopActions,
        VsSectionHeader,
        VsDropdownButton,
    },
})
export default class extends Vue {
    private show = true
    $refs: any

    @Prop({ default: () => [], required: true }) checkedContacts!: any[]

    get user () {
        return UserModule.user
    }

    get hasTag () {
        return this.user.configuration.rules.tag
    }

    private closeAndClear () {
        this.$emit('close-and-clear')
    }
}
