








































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
    ContactStatusEnum,
} from '@/api/consoleApi/types'
import { UserModule } from '@/store/modules/user'
import { getTags } from '@/api/consoleApi/recipients'

@Component({
    name: 'VsContactsFilter',
})
export default class extends Vue {
    @Prop({ required: false, default: true, type: Boolean }) hasEmail!: boolean
    @Prop({ required: false, default: true, type: Boolean }) hasMobile!: boolean
    @Prop({ required: false, default: () => [] }) cachedTags!: any[]
    private tags: any[] = []
    private open = false
    private loading = false

    private filters = {
        search: '',
        email: '',
        mobile: '',
        tags: [],
        status: [],
        searchJoin: 'and',
    }

    get statusOptions () {
        return [
            {
                value: ContactStatusEnum.optIn,
                label: 'Attesa conferma iscrizione',
            },
            {
                value: ContactStatusEnum.subscribed,
                label: 'Iscritto',
            },
            {
                value: ContactStatusEnum.unsubscribed,
                label: 'Disiscritto',
            },
        ]
    }

    get tagsOptions () {
        return this.tags.map(el => {
            return {
                value: el.id,
                label: el.name,
            }
        })
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    get Mixpanel () {
        return UserModule.Mixpanel
    }

    private save () {
        this.$emit('save', JSON.parse(JSON.stringify(this.filters)))
        this.trackMixpanelEvent('Contacts List Click Filter Save Button')
        this.closeModal()
    }

    public closeModal () {
        this.open = false
    }

    public async openModal (filters: any) {
        await this.getTags('')
        this.filters.search = ''
        this.filters.email = filters.email || ''
        this.filters.mobile = filters.mobile || ''
        this.filters.tags = JSON.parse(JSON.stringify(filters.tags)) || []
        this.filters.status = JSON.parse(JSON.stringify(filters.status)) || []
        this.filters.searchJoin = filters.searchJoin || 'and'
        this.trackMixpanelEvent('Contacts List Click Filter Button')
        this.open = true
    }

    private goToFilterLegacy () {
        this.trackMixpanelEvent('Contacts List Click Filter Legacy Button')
        window.open(`/app/user/subscribers/browse/${this.listId}`, '_blank')
    }

    private trackMixpanelEvent (eventName: string) {
        if (this.Mixpanel) {
            this.Mixpanel.track(
                eventName,
                {
                    distinct_id: this.user._id,
                    'Plan Type': this.user.configuration.name || '',
                    'List Id': this.listId,
                },
            )
        }
    }

    private async getTags (search: string) {
        this.loading = true
        try {
            const resp = await getTags(
                this.listId,
                {
                    limit: 50,
                    search: `name:${search}`,
                    searchFields: 'name:like',
                })
            this.tags = resp.data.data
            this.$emit('cached-tags-changed', resp.data.data)
        } catch (e) {
            this.tags = []
        }
        this.loading = false
    }

    formatBadgeLabel (item: any) {
        return this.cachedTags.find((el: any) => el.value === item)?.label || `Etichetta con id ${item} non trovata`
    }
}
