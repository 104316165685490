












































import { customFieldsValuesFormatter } from '@/utils/customFields'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsCustomFieldsTableValue',
})
export default class extends Vue {
    @Prop({ default: [], required: true }) customField!: any
    @Prop({ default: null, required: true }) contactId!: any
    @Prop({ default: false, required: false }) isUnique!: boolean

    get listId () {
        return this.$route?.params?.listId
    }

    get customFieldValue () {
        return customFieldsValuesFormatter(this.customField.value, this.customField.type)
    }
}
