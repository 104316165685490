



































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsManageTableColumnsModal from '@/modules/lists/components/VsManageTableColumnsModal/Index.vue'
import VsContactsFilter from '@/modules/lists/components/VsContactsFilter/Index.vue'
import VsContactsFilterMock from '@/modules/lists/components/VsContactsFilterMock/Index.vue'
import VsAssignTagModal from '@/modules/lists/components/VsAssignTagModal/Index.vue'
import CreateContactDrawer from '@/modules/lists/components/CreateContactDrawer/Index.vue'
import VsCustomFieldsTableValue from '@/modules/lists/components/VsCustomFieldsTableValue/Index.vue'
import VsContactsListTopMultiActions from '@/modules/lists/components/VsContactsListTopMultiActions/Index.vue'
import VsBlockedFunctionalityModal from '@/components/VsBlockedFunctionalityModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { getListContacts, getListCustomFields, deleteContactBulk, removeTagToContact, getTagById } from '@/api/consoleApi/recipients'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    CustomFieldTypeEnum,
    CustomField,
} from '@/utils/customFields'
import { UserModule } from '@/store/modules/user'
import {
    ContactStatusEnum,
} from '@/api/consoleApi/types'
import Details from './contacts/id/details.vue'
import { get, orderBy, unionBy } from 'lodash'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'ContactsList',
    components: {
        VsTable,
        VsManageTableColumnsModal,
        VsContactsListTopMultiActions,
        VsAssignTagModal,
        VsContactsFilter,
        VsContactsFilterMock,
        VsLoader,
        VsSectionHeader,
        CreateContactDrawer,
        VsDropdownButton,
        Details,
        VsCustomFieldsTableValue,
        VsBlockedFunctionalityModal,
        VsConfirm,
    },
})
export default class extends Vue {
    private customFields: CustomField[] = []
    private checkedContacts: any[] = []
    private items: any[] = []
    private tableColumns: any[] = []
    private openFilter = false
    private loading = false
    private pageLoader = false
    private showError = false
    private total = 0
    private cachedTags: any[] = []
    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    private filters: {
        search: string
        email: string
        mobile: string
        tags: any[]
        status: any[]
        searchJoin: 'and' | 'or'
    } = {
        search: '',
        email: '',
        mobile: '',
        tags: [],
        status: [],
        searchJoin: 'and',
    }

    $refs: any

    get hasMocks () {
        return AppModule.hasMocks
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get hasTag () {
        return this.userRules.tag
    }

    get canExportSubscribers () {
        return this.userRules.subscribersExport
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get sortField () {
        if (!this.pagination.orderBy) return 'id'
        return this.pagination.orderBy.split(',')[0]
    }

    get sortOrder () {
        if (!this.pagination.orderBy) return 'desc'
        return this.pagination.orderBy.split(',')[1]
    }

    get listsOrderBy () {
        return [
            {
                label: this.$t('lists.index.orderBy.createdAtDesc'),
                value: 'id,desc',
            },
            {
                label: this.$t('lists.index.orderBy.createdAtAsc'),
                value: 'id,asc',
            },
        ]
    }

    get user () {
        return UserModule.user
    }

    get hasEmail () {
        return this.customFields.find(el => el.type === CustomFieldTypeEnum.email)
    }

    get hasMobile () {
        return this.customFields.find(el => el.type === CustomFieldTypeEnum.mobile)
    }

    private isUnique (cfId: any) {
        return this.customFields.find(el => el.id === cfId && el.validation.unique)
    }

    get hasUniqueField () {
        return this.customFields.find(el => el.validation.unique)
    }

    get countFilters () {
        let count = 0
        if (this.filters.email) count++
        if (this.filters.mobile) count++
        if (this.filters.tags.length > 0) count++
        if (this.filters.status.length > 0) count++
        return count
    }

    get Mixpanel () {
        return UserModule.Mixpanel
    }

    get statusOptions () {
        return [
            {
                value: ContactStatusEnum.optIn,
                label: 'Attesa conferma iscrizione',
            },
            {
                value: ContactStatusEnum.subscribed,
                label: 'Iscritto',
            },
            {
                value: ContactStatusEnum.unsubscribed,
                label: 'Disiscritto',
            },
        ]
    }

    get filterTagsTip () {
        let text = ''
        this.cachedTags.forEach(tag => {
            if (this.filters.tags.includes(tag.value)) {
                text += tag.label + '<br>'
            }
        })
        return text
    }

    get filterStatusTip () {
        let text = ''
        this.filters.status.forEach(status => {
            text += this.$t(`lists.contact.status.${status}`) + '<br>'
        })
        return text
    }

    private changeSort (key: string) {
        this.pagination.orderBy = key !== this.sortField ? `${key},asc` : `${key},${this.sortOrder === 'desc' ? 'asc' : 'desc'}`
    }

    private openAssignTagModal (mode: 'add' | 'remove') {
        this.$refs.vsAssignTagModal.openModal(this.checkedContacts, mode)
    }

    private async getTags () {
        this.loading = true
        const tagPromises: Promise<any>[] = []
        for (const tag of this.filters.tags) {
            if (!this.cachedTags.find((el: any) => el.value === tag)) {
                tagPromises.push(getTagById(this.listId, tag))
            }
        }

        const responses = await Promise.allSettled(tagPromises)
        this.cachedTags = unionBy(
            this.cachedTags,
            responses
                .filter(el => el.status === 'fulfilled')
                .map((el: any) => {
                    const tag = el.value.data.data
                    return {
                        value: tag.id,
                        label: tag.name,
                    }
                }),
            'value',
        )
        this.loading = false
    }

    private setFilters (filters: any) {
        this.filters = filters
        window.localStorage.setItem(`${this.user?._id || ''}-${this.listId}-contact-filters`, JSON.stringify(this.filters))
        this.clearSelect()
    }

    private getTableHeaders () {
        let tableColumns: TableHeader[] = []
        this.customFields.forEach((cf: CustomField, index: number) => {
            let key = 'custom_fields'
            let id = cf.id
            let sortable = false
            const insertMethod: 'push' | 'unshift' = cf.validation.unique ? 'unshift' : 'push'
            let visible = index < 5

            if (cf.type === CustomFieldTypeEnum.email) {
                id = 'email_address'
                key = 'email_address'
                sortable = true
                visible = true
            }
            if (cf.type === CustomFieldTypeEnum.mobile) {
                id = 'mobile'
                key = 'mobile'
                sortable = true
                visible = true
            }
            tableColumns[insertMethod]({
                id,
                key,
                label: cf.name,
                sortable,
                visible,
            })
        })

        tableColumns.unshift({
            id: 'id',
            key: 'id',
            sortable: true,
            label: 'ID',
            visible: true,
        })

        tableColumns.push(
            {
                id: 'subscription.status',
                key: 'subscription.status',
                label: 'Stato iscrizione',
                formatter: (value: any) => {
                    return this.$t(`lists.contact.status.${value}`).toString()
                },
                visible: true,
            },
            {
                id: 'tags',
                key: 'tags',
                sortable: false,
                label: 'Etichette',
                visible: true,
            },
            {
                id: 'subscription.date',
                key: 'subscription.date',
                label: 'Data iscrizione',
                sortable: true,
                formatter: (value: any) => value === '0000-00-00 00:00:00' ? '' : this.$options?.filters?.formatDate(value),
                visible: true,
            },
        )

        let localTableColumns: any = window.localStorage.getItem(`${this.user?._id || ''}-${this.listId}-contacts-table`)
        if (localTableColumns) {
            localTableColumns = JSON.parse(localTableColumns)
        }

        if (localTableColumns) {
            tableColumns = tableColumns.map(el => {
                const col = localTableColumns.find((col: any) => col.id === el.id)
                return {
                    ...el,
                    visible: col ? col.visible : el.visible,
                }
            })
        }

        this.tableColumns = tableColumns
    }

    async beforeMount () {
        this.pageLoader = true
        if (this.$route.query.tagId) {
            if (this.hasTag) {
                this.filters.tags = [this.$route.query.tagId]
            }
            this.$router.replace({ query: {} })
            // window.localStorage.setItem(`${this.user?._id || ''}-${this.listId}-contact-filters`, JSON.stringify(this.filters))
        } else if (this.$route.query.status) {
            this.filters.status = [this.$route.query.status]
            this.$router.replace({ query: {} })
        } else {
            const filters = window.localStorage.getItem(`${this.user?._id || ''}-${this.listId}-contact-filters`)
            if (filters) this.filters = JSON.parse(filters)
        }
        await this.getCustomFields()
        await this.getListContacts()
        this.trackMixpanelEvent('Contacts List View')
        this.getTableHeaders()
        await this.getTags()
        this.pageLoader = false
    }

    private getCustomField (customFields: any[], colId: any) {
        const customField = customFields.find((cf: any) => cf.id === colId)
        if (!customField) return ''
        return customField
    }

    private async getCustomFields () {
        const cf: any[] = []
        await this.loopCall(1, cf, getListCustomFields)
        this.customFields = cf
    }

    async loopCall (page: number, values: any[], caller: any) {
        const resp = await caller(this.listId, {
            page,
            limit: 100,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    private resetFilters () {
        this.setFilters({
            search: '',
            email: '',
            mobile: '',
            tags: [],
            status: [],
            searchJoin: 'and',
        })
    }

    @Watch('checkedContacts', { deep: true, immediate: true })
    private emitChecked (val: any[]) {
        this.$emit('checked-contacts', val.length)
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.clearSelect()
        if (this.pagination.page === 1) {
            this.getListContacts()
        } else {
            this.pagination.page = 1
        }
    }

    @Watch('pagination', { deep: true, immediate: false })
    private async getListContacts () {
        try {
            this.loading = true
            const resp = await getListContacts(this.listId, this.buildParams())
            this.items = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    @Watch('tableColumns', { deep: true, immediate: false })
    saveColumnsLocalStorage () {
        window.localStorage.setItem(`${this.user?._id || ''}-${this.listId}-contacts-table`, JSON.stringify(this.tableColumns))
    }

    private getTagName (tagId: any) {
        const tag = this.cachedTags.find(el => el.value === tagId)
        if (!tag) return tagId
        return tag.label
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            include: 'tags',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
        }

        const searchFields: string[] = []
        const searches: string[] = []

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split(',')
            params.orderBy = order[0] === 'subscription.date' ? 'subscription_date;subscription_time' : order[0]
            params.sortedBy = order[1]
        }

        if (this.filters.search && this.filters.search.trim() !== '') {
            params.searchFields = 'email_address,mobile,id'
            params.search = this.filters.search
            return params
        }

        if (this.filters.email && this.filters.email.trim() !== '') {
            searchFields.push('email_address:like')
            searches.push(`email_address:${this.filters.email}`)
        }

        if (this.filters.mobile && this.filters.mobile.trim() !== '') {
            searchFields.push('mobile:like')
            searches.push(`mobile:${this.filters.mobile}`)
        }

        if (this.filters.tags && this.filters.tags.length > 0) {
            searchFields.push('tags.id:in')
            searches.push(`tags.id:${this.filters.tags.join(',')}`)
        }
        if (this.filters.status && this.filters.status.length > 0) {
            searchFields.push('subscription_status:in')
            searches.push(`subscription_status:${this.filters.status.join(',')}`)
        }

        params.searchFields = searchFields.join(';')
        params.search = searches.join(';')
        params.searchJoin = this.filters.searchJoin

        return params
    }

    private selectContacts (event: any) {
        this.checkedContacts = event
    }

    private selectAll () {
        this.$refs.contactsTable.selectAll()
    }

    private clearSelect () {
        this.checkedContacts = []
    }

    private async deleteContacts (contacts: any[]) {
        const contactsToDelete = {
            subscribers_id: contacts.toString(),
        }
        this.loading = true
        try {
            await deleteContactBulk(this.listId, contactsToDelete)
            await this.getListContacts()
            this.loading = false
            this.$root.$vsToast({
                aspect: VsToastAspectEnum.success,
                heading: 'Wow!',
                message: 'Contatti eliminati con successo',
                timeout: 3000,
            })
            this.clearSelect()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                aspect: VsToastAspectEnum.alert,
                heading: 'Ops!',
                message: 'Qualcosa è andato storto',
                timeout: 3000,
            })

            const error = get(e, 'response.data.message.0.0', null)
            if (error === 'Subscriber not deletable') {
                this.showError = true
            }
            this.clearSelect()
            await this.getListContacts()
        }
        this.loading = false
    }

    private async openManageTableColumnsManageModal () {
        try {
            const tableColumns = await this.$refs.vsManageTableColumnsModal.open(this.tableColumns)
            this.tableColumns = this.tableColumns.map(el => {
                const col = tableColumns.find((col: any) => col.id === el.id)
                return {
                    ...el,
                    visible: col ? col.visible : el.visible,
                }
            })
        } catch (e) {
            console.log(e)
        }
        this.$refs.vsManageTableColumnsModal.close()
    }

    private otherTags (contactId: string) {
        this.$router.push({ name: 'contactTags', params: { contactId } })
    }

    private orderTags (tags: any[]) {
        return orderBy(tags, 'created_at', 'desc')
    }

    private trackMixpanelEvent (eventName: string) {
        if (this.Mixpanel) {
            this.Mixpanel.track(
                eventName,
                {
                    distinct_id: this.user?._id,
                    'Plan Type': this.user?.configuration.name || '',
                    'List Id': this.listId,
                },
            )
        }
    }

    private async removeAssign (tagId: any, contactId: number) {
        try {
            await this.$refs.removeConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await removeTagToContact(this.listId, tagId, contactId)

            this.$root.$vsToast({
                heading: 'Etichetta rimossa con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            await this.getListContacts()
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private openBlockedFunctionality (e: any, isBlocked: boolean) {
        if (isBlocked) {
            e.preventDefault()
            this.$refs.blockedFunctionality.openModal()
        }
    }

    private setCachedTags (tags: any[]) {
        this.cachedTags = unionBy(
            this.cachedTags,
            tags
                .map((tag: any) => {
                    return {
                        value: tag.id,
                        label: tag.name,
                    }
                }),
            'value',
        )
    }

    async generateCsv () {
        const headers = this.tableColumns.filter(el => el.visible).map(el => el.label)
        return headers
    }
}
